import React from "react";
import harto from "../images/harto.png";
import Intro from "./Intro";

const Artist = () => {
  return (
    <div>
      <div className="bg-[#F4A699] h-screen ">
        <div className="flex flex-col-reverse  lg:flex-row items-center h-full justify-center">
          <div className=" w-full lg:w-1/2 px-10 text-left ">
            <p className="  text-2xl md:text-4xl font-bold text-white lg:mt-20 lg:mx-20 ">
              Harto
            </p>
            <p className="  text-xl md:text-2xl font-bold text-white mt-2 lg:mx-20 ">
              Meet the artist.
            </p>
            <p className="  text-sm md:text-xl font-semibold text-white mt-2 lg:mt-10 lg:mx-20 ">
              Arturo Fernández Rodríguez, alias Harto (Barcelona,Spain b. 1993)
              is a multidisciplinary artist and designer inspired by nature,
              theater and new technologies.Graduated in industrial design and
              prototyping in 2016, he will work as a creator of art
              installations and street shows, working on international shows
              until 2020. In 2020, Harto decides to fully immerse himself in
              digital creation and the world of NFTs, where a few months later
              he will achieve some exhibitions in international marketplaces and
              galleries. Highlights include works exhibited in New York, Miami,
              Hong Kong and a project with 50 international artists that took
              the first NFT into space thanks to the Rakia mission, supported by
              Space X and NASA.
            </p>
          </div>

          <div className=" w-full lg:w-1/2 px-10 flex flex-col items-center justify-center ">
            <img className=" w-[250px] lg:w-[600px] p-2 lg:p-10" src={harto} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artist;
