import React from "react";
import lines from "../images/lines.png";
import img2 from "../images/2.png";
import img3 from "../images/3.png";
import img4 from "../images/4.png";
import img7 from "../images/7.png";
import img8 from "../images/8.png";
import img27 from "../images/27.png";

const imgClass = " w-[80px] md:w-[110px]  lg:w-[150px] mx-4  my-2 hover:scale-110 transition-all"

const Intro = () => {
  return (
    <div className="bg-[#4C6FCB] h-screen relative ">
      <img
        className=" w-[300px] lg:w-[400px] xl:w-[500px] absolute left-0 top-0 z-0"
        src={lines}
      />
      <div className="absolute bottom-0">
      <div className="w-full lg:w-2/3 z-40  block float-right lg:px-20 xl:mb-20">
        <p className=" text-xl lg:text-4xl text-center py-32 text-white font-bold w-full xl:w-1/2  ">
          125 3D GENERATIVE BLOCKS REPRESENTING THE INFINITE WAYS TO BUILD A
          CUBE.
        </p>
      </div>

      <div className="flex flex-wrap justify-center  w-full  my-10">
        <img className={imgClass} src={img2} />
        <img className={imgClass} src={img3} />
        <img className={imgClass} src={img4} />
        <img className={imgClass} src={img7} />
        <img className={imgClass} src={img8} />
        <img className={imgClass} src={img27} />
      </div>
      </div>
    </div>
  );
};

export default Intro;
