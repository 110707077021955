import React from "react";
import block from "../images/custom1.png";
import Intro from "./Intro";

const Price = () => {
  return (
    <div>
      <div className="bg-black h-screen ">
        <div className="flex flex-col lg:flex-row items-center h-full justify-center">
          <div className=" w-full lg:w-1/2 px-10 ">
            <p className="text-4xl font-bold text-white mt-20 ">
              1 BLOCK = 1 BLOCK
            </p>
            <p className=" text-2xl lg:text-4xl  text-white mt-8 ">
              BLOCKS IS AN ARTISTIC PROJECT.
            </p>
            <p className=" text-2xl lg:text-4xl  text-white mt-2 ">0 ROADMAP</p>
            <p className=" text-2xl lg:text-4xl  text-white mt-8 ">ONLY ART.</p>
            
          </div>

          <div className=" w-full lg:w-1/2 px-10 flex flex-col items-center justify-center ">
            <img className=" w-[300px] lg:w-[400px] p-10" src={block} />
            <p className="text-4xl  text-white font-bold text-center ">0.027 Ξ</p>

          </div>
        </div>
      </div>

    </div>
  );
};

export default Price;
