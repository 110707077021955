import React from "react";
import logo from "../images/logo1.png";
import tw from "../images/Twitter-logo1.png";
import Intro from "./Intro";

const Footer = () => {
  return (
    <div>
      <div className="bg-[#B9BDAE] h-screen ">
        <div className="flex flex-col lg:flex-row items-center h-full justify-center">
          <div className=" w-full lg:w-1/2 px-10 flex flex-col items-center justify-center ">
            <img className=" w-[500px] lg:w-[700px] p-2 lg:p-10" src={logo} />
            <div className="flex justify-start mt-20 lg:mt-10">
              <p className="text-3xl text-white">Join us on </p>
              <a href="https://twitter.com/harto_fr" target="_blank">

              <img className=" w-[40px]  ml-4 " src={tw} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
