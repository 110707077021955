import React, { useState, useEffect } from "react";
import block from "../block.png";
import loadingblock from "../images/loadingblock.png";
import Intro from "./Intro";
import Price from "./Price";
import Concept from "./Concept";
import Artist from "./Artist";
import Congrats from "./Congrats";
import Footer from "./Footer";
import ABI from "./ABI.json";

import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const statuses = {
  WAITING: "WAITING",
  DISCONNECTED: "DISCONNECTED",
  CONNECTED: "CONNECTED",
};

const Home = () => {
  const [isloading, setIsloading] = useState("false");
  const [status, setStatus] = useState(statuses.WAITING); // waiting, disconnected, connected
  const [address, setAddress] = useState("");

  const CONTRACTADDRESS = "0xCbC8A5472Bba032125C1A7d11427AA3B5035207b";

  useEffect(() => {
    const saledate = new Date("January 9, 2023 17:00:00 PST");
    console.log("DATE", saledate.toString(), new Date().toString());

    if (new Date() > saledate) {
      console.log(saledate.toString());
      console.log("Sale started");
      setStatus(statuses.DISCONNECTED);
    }
  }, []);

  useEffect(() => {
    if (!window.ethereum) return;
    window.ethereum.on("accountsChanged", () => {
      // console.log("Account chaneged");
      // setaddress(null);
      window.location.reload();
    });
  }, []);

  const connect = async () => {
    // window.ethereum.request
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      console.log("No metamask");
      toast("Please use a browser with Metamask");
      return;
    }
    //check chain id
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    // console.log(typeof chainId);
    if (chainId !== "0x1") {
      console.log("Wrogn Chain");
      toast("Please use Etherium Mainnet ");
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    // console.log(provider);
    const signer = provider.getSigner();

    setAddress(await signer.getAddress());
    setStatus(statuses.CONNECTED);
    toast(`Connected with ${await signer.getAddress()} `);
  };


  const delay = (ms) => new Promise((res) => setTimeout(res, ms));


  const mint = async (price, num, type) => {
    console.log("Minting ");
    // window.ethereum.request
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      console.log("No metamask");
      alert.error("Please use a browser with Metamask");
      return;
    }
    //check chain id
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    if (chainId !== "0x1") {
      console.log("Wrogn Chain");
      alert.error("Please use Etherium Mainnet ");
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const provider = new ethers.providers.getDefaultProvider("inkeby", {
    //   infura: "https://mainnet.infura.io/v3/44c345ec9cac43e3a461dbfffe965e5d",
    // });

    try {
      // console.log(await signer.getAddress());
      const signer = provider.getSigner();
      // setaddress(await signer.getAddress());

      let contract = new ethers.Contract(CONTRACTADDRESS, ABI, signer);
      // console.log(contract);
      let address = await signer.getAddress();
      // console.log("ADDRESS", address);
      setAddress(address);
      //infura
      setIsloading("true");
      let priceInWei = ethers.utils.parseEther("0.027");

      let transaction = null;

      // console.log("sale", priceInWei.toNumber());
      let gas = await contract.estimateGas.mintWhitelist({
        value: "27000000000000000",
      });
      gas = gas.mul(3);
      gas = gas.div(2);
      transaction = await contract.mintWhitelist({
        value: "27000000000000000",
        gasLimit: gas,
      });
      console.log(transaction)
      await transaction.wait();

      // await delay(2000);
      setIsloading("done");
      toast("You Minted Your NFT!");
      await delay(3000);
      setIsloading("false");
    } catch (err) {
      console.log(err);
      if (err.message.includes("unknown account")) {
        toast("Please connect your Metamask Wallet!");
      } else if (err.message.includes("Exceeds MAX_Supply")) {
        toast("Payment Failed,SOLD!!!");
      } else if (err.message.includes("insufficient funds")) {
        toast("Payment Failed,Insufficient funds in your account!");
      } else if (err.message.includes("exceeds max allowed")) {
        toast(
          "Payment Failed,Sorry,This sale is sold out! Please mint from piublic sale."
        );
      } else if (err.message.includes("not on the White List")) {
        toast("Payment Failed,Your account is not on the Whitelist!");
      } else toast("Payment Failed, please try again!");
      setIsloading("false");
    }
  };

  const LoadingModal = () => {
    return (
      <>
        {isloading != "false" ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-full  ">
                {/*content*/}
                <div className=" relative flex flex-col w-screen h-screen  outline-none focus:outline-none text-white">
                  {/*header*/}
                  <div className="flex items-center justify-end "></div>
                  <div
                    className={`flex items-center justify-between px-16    rounded-t ${
                      isloading == "true" ? "bg-[#F4A699]" : "bg-[#B9BDAE]"
                    }  h-full `}
                  >
                    <h3 className="text-5xl text-white text-center  w-full">
                      {isloading == "true" ? (
                        <>Minting... </>
                      ) : (
                        <>
                          <div className=" flex items-center flex-col">
                            <img
                              className=" w-[600px] lg:w-[400px] "
                              src={loadingblock}
                            />
                            <p>Congrats</p>
                          </div>
                        </>
                      )}
                    </h3>
                  </div>
                  {/*footer*/}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div>
      <LoadingModal />
      <div className="bg-[#F14726] h-screen ">
        <div className="flex flex-col items-center">
          <div className="- my-20 ">
            <img className=" w-[600px] lg:w-[600px] pt-10" src={block} />
            {status == statuses.CONNECTED ? (
              <>
                <button
                  onClick={mint}
                  className="bg-white border border-black border-b-2 rounded-lg px-5 py-3 text-xl font-semibold"
                >
                  MINT
                </button>
              </>
            ) : status == statuses.DISCONNECTED ? (
              <>
                <button
                  onClick={connect}
                  className="bg-white border border-black border-b-2 rounded-lg px-5 py-3 text-xl font-semibold"
                >
                  CONNECT
                </button>
              </>
            ) : (
              <>
                <button className="bg-white border border-black border-b-2 rounded-lg px-5 py-3 text-xl font-semibold">
                  Going Live 5PM, 9th Jan PT
                </button>
              </>
            )}
          </div>
        </div>
        <p className="text-7xl lg:text-[160px] font-bold text-white absolute bottom-0 p-4 uppercase">
          Blocks.
        </p>
      </div>
      <ToastContainer />

      <Intro />
      <Price />
      <Concept />
      <Artist />
      <Congrats />
      <Footer />
    </div>
  );
};

export default Home;
