import React from "react";
import lines from "../images/lines2.png";
import Intro from "./Intro";

const Concept = () => {
  return (
    <div>
      <div className="bg-[#E8AAD7] h-screen ">
        <div className="flex flex-col lg:flex-row items-center h-full justify-center">
          

          <div className=" w-full lg:w-1/2 px-10 flex flex-col items-center justify-center ">
            <img className=" w-[500px] lg:w-[700px] p-2 lg:p-10" src={lines} />

          </div>

          <div className=" w-full lg:w-1/2 px-10 ">
            <p className="  text-xl md:text-3xl font-bold text-white mt-20 lg:mx-20 ">
            "The art was generated inspired by the merkle parent principle, generating all the blocks from the first cubic form (0,0,0) creating a root of artworks and variations in colors."
            </p>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Concept;
