import React from "react";
import dobey from "../images/dd.png";
import billy from "../images/billy1.png";
import hash from "../images/frame.png";

const Congrats = () => {
  return (
    <div>
      <div className="bg-[#FFC6C6] h-screen ">
        <p className="  text-2xl md:text-5xl font-bold text-white pt-4 lg:pt-20 ">
          Team and collaborators
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-center mt-4 lg:mt-20">
          <div className=" mt-4 lg:mt-20 mx-10">
            <img className=" w-[200px] lg:w-[300px]" src={dobey} />
            <p className="  text-xl font-bold text-white  ">@DobeyDick</p>
            <p className="  text-xl font-bold text-white mx-2  ">
              Partnership Lead
            </p>
          </div>

          <div className=" mt-4 lg:mt-20 mx-10">
            <img className=" w-[180px] lg:w-[260px]" src={billy} />
            <p className="  text-xl font-bold text-white  ">@BillyTheBilder</p>
            <p className="  text-xl font-bold text-white mx-2  ">
            Strategy Lead
            </p>
          </div>

          <div className=" mt-4 lg:mt-20 mx-10">
            <img className=" bg-white rounded-full w-[160px] lg:w-[230px]" src={hash} />
            <p className="  text-xl font-bold text-white mt-4 ">@HashCase</p>
            <p className="  text-xl font-bold text-white mx-2  ">
            Tech Lead
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congrats;
